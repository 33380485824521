<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<style lang="scss" scoped>
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>

<script>
import localData from '../../../utils/saveDataToLocal';

export default {
  components: {},
  methods: {
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    }
  }
};
</script>
